import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const SERVICES = ['ilmo', 'ori', 'kori', 'arto', 'osuva', 'paino', 'tasku', 'virta', 'api'] as const;
type Service = typeof SERVICES[number];

export interface ServiceStatus {
    build?: string;
    name: Service;
    status: string;
    version?: string;
}

interface InfoResponse {
    build: string;
    version: string;
}

@Injectable()
export class StatusService {

    private readonly http = inject(HttpClient);

    getServiceStatus(): Observable<ServiceStatus[]> {
        return combineLatest(
            SERVICES.map(service => this.http.get<InfoResponse>(`/${service}/info`, { observe: 'response' })
                .pipe(
                    map((response) => ({
                        ...response.body,
                        name: service,
                        status: `${response.status} ${response.statusText}`,
                    })),
                    catchError((err => of({
                        name: service,
                        status: err instanceof HttpErrorResponse ? `${err.status} ${err.statusText}` : 'N/A',
                    }))),
                ),
            ),
        );
    }
}
