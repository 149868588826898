import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { NgbActiveModal, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import { ClipboardModule } from 'ngx-clipboard';
import { LoggerModule, NGXLogger } from 'ngx-logger';
import {
    authServiceProvider,
    defaultPromiseHandlerProvider,
    tokenStorageProvider,
    translationLoaderProvider,
} from 'sis-common/ajs-upgraded-modules';
import { HybridInjectorService } from 'sis-common/angular-hybrid/hybrid-injector.service';
import { ServiceBootstrapComponent } from 'sis-common/angular-hybrid/service-bootstrap.component';
import { appAuthInitializer } from 'sis-common/auth/app-auth-initializer';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';
import { httpInterceptorProviders } from 'sis-common/http-interceptors/index';
import { IdleService } from 'sis-common/idle/idle-ng.service';
import { InjectorService } from 'sis-common/injector/injector.service';
import { initialLanguageProvider } from 'sis-common/l10n/initial-language';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { HIGHEST_LOGGING_LEVEL, setUpLogging } from 'sis-common/logging/logging';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { TranslocoCommonModule } from 'sis-common/transloco/transloco-common.module';
import {
    calendarEventServiceProvider,
    commonEducationServiceProvider,
    commonEnrolmentServiceProvider,
    commonStudyRightServiceProvider,
    commonTermRegistrationPeriodServiceProvider,
    courseUnitRealisationNameServiceProvider,
    courseUnitServiceProvider,
    curriculumPeriodServiceProvider,
    editModeServiceProvider, legacyTranslateServiceProvider,
    planSelectorServiceProvider,
    studyEventServiceProvider,
    validAttainmentFilterServiceProvider,
} from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { LogService } from 'sis-components/error-handler/log.service';
import { apolloFactory } from 'sis-components/graphql/front-end-graphql-initializer';
import { HeaderService } from 'sis-components/header/header.service';
import { transitionErrorHandler } from 'sis-components/router/hooks';
import { ConnectionStatusService } from 'sis-components/service/connection-status.service';
import { JsDataSyncService } from 'sis-components/service/js-data-sync.service';
import {
    SessionTimeoutWarningService,
} from 'sis-components/session-timeout-warning/session-timeout-warning.service';
import { SisComponentsModule } from 'sis-components/sis-components.module';
import { NavUtilsService } from 'sis-components/topNav/nav-utils/nav-utils.service';

import { environment } from '../environments/environment';

import {
    attainmentServiceProvider,
    choosePlanServiceProvider,
    courseUnitInfoModalProvider,
    enrolmentModalServiceProvider,
    moduleAttainmentApplicationServiceProvider,
    organisationJSDataModelProvider,
    planSeekerProvider,
    previewModeProvider,
    studentProfileServiceProvider,
    studyPeriodServiceProvider,
    studyProgressGraphDropDownContentFetcherProvider, uiContextProvider,
} from './ajs-upgraded-modules';
import { StudentAuthRouteModule } from './auth/student-auth-route.module';
import { StudentAuthRouteService } from './auth/student-auth-route.service';
import { studentRoutes } from './auth/student-routes';
import { CalendarModule } from './calendar/calendar.module';
import { StudentCommonModule } from './common/common.module';
import {
    CourseUnitAttainmentNotificationComponent,
} from './common/components/course-unit-attainment-notification/course-unit-attainment-notification.component';
import { CourseUnitGradeRaiseInfoComponent } from './common/components/course-unit-grade-raise-info/course-unit-grade-raise-info.component';
import { CourseUnitSelectionInfoComponent } from './common/components/course-unit-selection-info/course-unit-selection-info.component';
import { StudentTranslateLoader } from './common/translate/student-translate-loader';
import { TranslocoStudentLoader } from './common/transloco/transloco-student-loader';
import { CourseCartComponent } from './course-cart/course-cart.component';
import { EnrolmentsModule } from './enrolments/enrolments.module';
import {
    ContinueToEnrolmentModalComponent,
} from './enrolments/teaching-not-selected/continue-to-enrolment-modal/continue-to-enrolment-modal.component';
import {
    SelectCourseUnitRealisationComponent,
} from './enrolments/teaching-not-selected/select-course-unit-realisation/select-course-unit-realisation.component';
import { FrontpageModule } from './frontpage/frontpage.module';
import { MessageConversationsModule } from './message-conversations/message-conversations.module';
import { OpenUniversityModule } from './open-university/open-university.module';
import { PlanNgModule } from './plan-ng/plan-ng.module';
import {
    EmrexResultModalComponent,
} from './plan/emrex-result-modal/emrex-result-modal.component';
import { ModuleContentWorkflowModalComponent } from './plan/module-content-workflow-modal/module-content-workflow-modal.component';
import { OutdatedCourseUnitsModalComponent } from './plan/outdated-course-units-modal/outdated-course-units-modal.component';
import { PlanModule } from './plan/plan.module-ng';
import { SelectCompletionMethodComponent } from './plan/select-completion-method/select-completion-method.component';
import { SelectEmrexAccessPointModalComponent } from './plan/select-emrex-access-point-modal/select-emrex-access-point-modal.component';
import { StudentAttainmentNodeWrapperComponent } from './profile/attainments/student-attainments/student-attainment-node-wrapper/student-attainment-node-wrapper.component';
import { StudentAttainmentsComponent } from './profile/attainments/student-attainments/student-attainments.component';
import { BasicInfoPersonalInfoDetailsComponent } from './profile/basic-info/basic-info-personal-info-details/basic-info-personal-info-details.component';
import { BasicInfoPersonalInfoHistoryComponent } from './profile/basic-info/basic-info-personal-info-history/basic-info-personal-info-history.component';
import { BasicInfoPersonalInfoComponent } from './profile/basic-info/basic-info-personal-info/basic-info-personal-info.component';
import { BasicInfoComponent } from './profile/basic-info/basic-info.component';
import { ContactInformationComponent } from './profile/basic-info/contact-information/contact-information.component';
import { EditContactInformationComponent } from './profile/basic-info/edit-contact-information/edit-contact-information.component';
import { ProfileModule } from './profile/profile.module-ng';
import { SearchModule } from './search/search.module';
import { TimingModule } from './timing/timing.module';

@NgModule({
    declarations: [
        ServiceBootstrapComponent,
        BasicInfoPersonalInfoComponent,
        BasicInfoPersonalInfoDetailsComponent,
        BasicInfoPersonalInfoHistoryComponent,
        BasicInfoComponent,
        CourseCartComponent,
        EditContactInformationComponent,
        ContactInformationComponent,
        ModuleContentWorkflowModalComponent,
        OutdatedCourseUnitsModalComponent,
        CourseUnitAttainmentNotificationComponent,
        CourseUnitSelectionInfoComponent,
        CourseUnitGradeRaiseInfoComponent,
        SelectCompletionMethodComponent,
        SelectCourseUnitRealisationComponent,
        SelectEmrexAccessPointModalComponent,
        EmrexResultModalComponent,
        ContinueToEnrolmentModalComponent,
        StudentAttainmentsComponent,
        StudentAttainmentNodeWrapperComponent,
    ],
    imports: [
        PlanNgModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        BrowserModule,
        CalendarModule,
        ClipboardModule,
        EnrolmentsModule,
        FrontpageModule,
        LoggerModule.forRoot({
            level: HIGHEST_LOGGING_LEVEL,
        }),
        MessageConversationsModule,
        PlanModule,
        ProfileModule,
        SisComponentsModule,
        StudentCommonModule,
        TimingModule,
        OpenUniversityModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: StudentTranslateLoader,
            },
        }),
        UIRouterUpgradeModule.forRoot({
            config: uiRouter => {
                // Override the default error handler that prints errors to console, handle errors in transition hook
                uiRouter.stateService.defaultErrorHandler(() => {});
                uiRouter.transitionService.onError({}, transitionErrorHandler(studentRoutes.defaultView));
            },
        }),
        SisCommonModule,
        NgIdleModule.forRoot(),
        ApolloModule,
        SearchModule,
        TranslocoCommonModule.forRoot({
            loader: TranslocoStudentLoader,
        }),
        ReactiveFormsModule,
        FormsModule,
        FullCalendarModule,
        NgxFudisModule,
        StudentAuthRouteModule,
    ],
    providers: [
        NgbActiveModal,
        uiContextProvider,
        organisationJSDataModelProvider,
        authServiceProvider,
        choosePlanServiceProvider,
        commonEducationServiceProvider,
        commonEnrolmentServiceProvider,
        commonStudyRightServiceProvider,
        commonTermRegistrationPeriodServiceProvider,
        courseUnitServiceProvider,
        courseUnitInfoModalProvider,
        curriculumPeriodServiceProvider,
        defaultPromiseHandlerProvider,
        httpInterceptorProviders,
        editModeServiceProvider,
        planSeekerProvider,
        planSelectorServiceProvider,
        studyProgressGraphDropDownContentFetcherProvider,
        translationLoaderProvider,
        tokenStorageProvider,
        studentProfileServiceProvider,
        enrolmentModalServiceProvider,
        studyPeriodServiceProvider,
        curriculumPeriodServiceProvider,
        studyEventServiceProvider,
        previewModeProvider,
        attainmentServiceProvider,
        validAttainmentFilterServiceProvider,
        { provide: LOCALE_ID, useValue: 'fi' },
        { provide: ErrorHandler, useExisting: AppErrorHandler },
        {
            provide: NG_ENTITY_SERVICE_CONFIG,
            useValue: {},
        },
        initialLanguageProvider,
        calendarEventServiceProvider,
        appAuthInitializer,
        courseUnitRealisationNameServiceProvider,
        {
            provide: APOLLO_OPTIONS,
            useFactory: apolloFactory,
            deps: [HttpBatchLink],
        },
        JsDataSyncService,
        EnvironmentService.forRoot(environment),
        { provide: InjectorService, useClass: HybridInjectorService },
        legacyTranslateServiceProvider,
        moduleAttainmentApplicationServiceProvider,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    constructor(
        private logger: NGXLogger,
        private ngbDropdownConfig: NgbDropdownConfig,
        private localeService: LocaleService,
        private idleService: IdleService,
        private sessionTimeoutWarningService: SessionTimeoutWarningService,
        private connectionStatusService: ConnectionStatusService,
        private jsDataSyncService: JsDataSyncService,
        private studentLoginRouteService: StudentAuthRouteService,
        private logService: LogService,
        headerService: HeaderService,
        navUtilsService: NavUtilsService,
    ) {
        this.logService.init('/osuva/jsnlog.logger');
        setUpLogging(this.logger);
        this.ngbDropdownConfig.autoClose = 'outside';
        headerService.init();
        navUtilsService.init();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method,@angular-eslint/use-lifecycle-interface
    ngDoBootstrap() {}
}

