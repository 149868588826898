import { Transition } from '@uirouter/core';
import { AuthSelectionPageComponent } from 'sis-common/auth/auth-selection-page/auth-selection-page.component';
import { SisStateDeclaration } from 'sis-components/router/types';

import { studentRoutes } from './student-routes';

export const loginStates: SisStateDeclaration[] = [
    {
        name: 'student.login',
        url: '/login?target&loggedOut',
        data: {
            hideHeader: true,
        },
        headerParams: {
            skipBreadcrumb: true,
            displayNameKey: 'NAVIGATION.LOGIN',
        },
        component: AuthSelectionPageComponent,
        resolve: {
            loginTarget: getLoginTarget,
            continueWithoutLogin: getAnonymousLoginState,
        },
    },
];

function getLoginTarget($transition$: Transition) {
    return $transition$.options().custom.customTargetPath ?? $transition$.options().custom.loginTarget ?? window.location.href;
}

getLoginTarget.$inject = ['$transition$'];

function getAnonymousLoginState() {
    return studentRoutes.continueWithoutLogin;
}

getAnonymousLoginState.$inject = ['$transition$'];
