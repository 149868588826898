import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

import { UniversityService } from '../service/university.service';

@Component({
    selector: 'sis-footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

    @Input({ required: true }) aboutHref: string;

    constructor(
        private transloco: TranslocoService,
        private universityService: UniversityService,
    ) {}

    registryDescriptionUrl = toSignal(this.universityService.getCurrentUniversitySettings()
        .pipe(
            map(settings => settings?.registryDescriptionUrl),
            catchError(() => of(null)),
        ));

    currentLanguage = toSignal(this.transloco.langChanges$
        .pipe(startWith(this.transloco.getActiveLang() ?? this.transloco.getDefaultLang())));
}
